import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import StatsAgents from "@/game-val/StatsAgents.jsx";
import StatsMaps from "@/game-val/StatsMaps.jsx";
import StatsWeapons from "@/game-val/StatsWeapons.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

const TABS = {
  maps: {
    component: <StatsMaps />,
    url: "/valorant/stats/maps",
    title: ["common:maps", "Maps"],
    meta: {
      title: ["val:meta.stats.maps.title", "VALORANT Map Stats"],
      description: [
        "val:meta.stats.maps.description",
        "Discover the strategic depth of VALORANT with our detailed map stats tracker. Explore what maps have the highest rates of success to excel in your matches.",
      ],
      subtitle: true,
    },
  },
  agents: {
    component: <StatsAgents />,
    url: "/valorant/stats/agents",
    title: ["common:agents", "Agents"],
    meta: {
      title: ["val:meta.stats.agents.title", "VALORANT Agent Stats"],
      description: [
        "val:meta.stats.agents.description",
        "Unlock your potential in VALORANT with our advanced Agent stats tracker. Dive into key statistics, and discover the top agents in the meta to enhance your gameplay experience.",
      ],
      subtitle: true,
    },
  },
  weapons: {
    component: <StatsWeapons />,
    url: "/valorant/stats/weapons",
    title: ["common:weapons", "Weapons"],
    meta: {
      title: ["val:meta.stats.weapons.title", "VALORANT Weapons Stats"],
      description: [
        "val:meta.stats.weapons.description",
        "Explore the arsenal of VALORANT with our weapons stats tracker. Explore key statistics for every weapon used by players all over the world.",
      ],
      subtitle: true,
    },
  },
};

export default function Stats() {
  const { t } = useTranslation();

  const links = useMemo(
    () =>
      Object.values(TABS).map((tab) => ({
        url: tab.url,
        text: t(...tab.title),
      })),
    [t],
  );

  const {
    parameters: [tab],
  } = useRoute();

  const currTab = TABS[tab];

  return (
    <PageContainer>
      <PageHeader
        title={t("common:navigation.statistics", "Statistics")}
        links={links}
      />
      {currTab?.component}
    </PageContainer>
  );
}

export function meta(tabId) {
  const tab = TABS[tabId];

  return {
    ...(tab?.meta ?? {}),
  };
}
