import React from "react";
import { styled } from "goober";

import { getMapImage } from "@/game-val/utils.mjs";

const MapContainer = styled("div")`
  border: 1px solid var(--shade5);
  background-size: cover;
  width: var(--sp-8_5);
  height: var(--sp-8_5);
  background-image: url(${({ $bg }) => $bg});
  border-radius: var(--br-sm);
`;

const MapBlock = ({ mapKey }) => {
  const $bg = getMapImage(mapKey);

  return <MapContainer $bg={$bg} />;
};

export default MapBlock;
