import React, { useMemo } from "react";
import type { SelectProps } from "clutch/src/Select/Select.jsx";
import { Select } from "clutch/src/Select/Select.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { getValorantRankImage } from "@/game-val/utils.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function SelectRank({
  selected,
  ...restProps
}: Omit<SelectProps<string>, "options">) {
  const {
    val: { content: { ranks = [] } = {} },
  } = useSnapshot(readState);

  const options = useMemo(
    () =>
      ranks.map(({ tier, key, title, rank, position }) => {
        return {
          image: getValorantRankImage({ rank, tier }),
          text: [`val:ranks.${key}`, title] as Translation,
          value: position.toString(),
        };
      }),
    [ranks],
  );

  return (
    <Select
      selected={selected || options[0].value}
      options={options}
      {...restProps}
    />
  );
}
