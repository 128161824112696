import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Select } from "clutch/src/Select/Select.jsx";
import { TextInput } from "clutch/src/TextInput/TextInput.jsx";

import {
  ABILITY_COLS,
  GENERAL_COLS,
  getAgentAbilityRow,
  getAgentRow,
} from "@/game-val/components/Agents.jsx";
import SelectAct from "@/game-val/SelectAct.jsx";
import SelectMap from "@/game-val/SelectMap.jsx";
import SelectMode from "@/game-val/SelectMode.jsx";
import SelectRank from "@/game-val/SelectRank.jsx";
import type { AgentStatsParams } from "@/game-val/stats-utils.mjs";
import {
  AGENTS_OPTIONS,
  getStatsParams,
  updateStatsParams,
  useDefaultTableParams,
  useGetAgentsData,
} from "@/game-val/stats-utils.mjs";
import ContentContainer from "@/shared/ContentContainer.jsx";
import type { Column } from "@/shared/DataTable.jsx";
import DataTable from "@/shared/DataTable.jsx";
import FilterBar from "@/shared/FilterBar.jsx";
import { useRoute } from "@/util/router-hooks.mjs";

export function GeneralTableView({ searchText }) {
  const { t } = useTranslation();
  const { searchParams } = useRoute();

  const defaultParams = useDefaultTableParams("agents");
  const data = useGetAgentsData(searchParams, searchText);

  const cols = GENERAL_COLS(t);
  const rows = useMemo(() => {
    if (!data) return null;
    return data.map((stats) => getAgentRow(stats));
  }, [data]);

  return (
    <DataTable
      {...defaultParams}
      indexCol
      cols={cols}
      rows={rows}
      sortCol={3}
      sortDir="DESC"
    />
  );
}

function AbilitiesTableView({ searchText }) {
  const { t } = useTranslation();
  const { searchParams } = useRoute();

  const defaultParams = useDefaultTableParams("agents");
  const data = useGetAgentsData(searchParams, searchText);

  const cols: Column[] = ABILITY_COLS(t);

  const rows = useMemo(() => {
    if (!data) return null;
    return data.map((stats) => getAgentAbilityRow(stats));
  }, [data]);

  return (
    <DataTable
      {...defaultParams}
      indexCol
      cols={cols}
      rows={rows}
      sortCol={0}
      sortDir="ASC"
    />
  );
}

const Filters = ({ searchText, setSearch }) => {
  const { searchParams, currentPath } = useRoute();
  const { t } = useTranslation();

  const { act, rank, map, mode, type } = getStatsParams(
    searchParams,
    "agents",
  ) as AgentStatsParams;

  const updateFilters = useCallback(
    (key, val) => {
      const payload: Record<string, string> = { [key]: val };
      // Reset map on change to or from TDM
      if (
        key === "mode" &&
        (mode === "hurm" || val === "hurm") &&
        map !== "all"
      ) {
        payload.map = "all";
      }

      updateStatsParams({ searchParams, currentPath }, payload, "agents");
    },
    [searchParams, currentPath, map, mode],
  );

  const isCompetitive = useMemo(() => mode === "competitive", [mode]);
  return (
    <FilterBar>
      <TextInput
        defaultValue={searchText}
        placeholder={t("val:search.agents", "Search Agents")}
        onValueChange={setSearch}
      />
      <Select
        selected={type}
        options={AGENTS_OPTIONS}
        onChange={(v) => updateFilters("type", v)}
      />
      <SelectMode
        stats
        selected={mode}
        onChange={(v) => updateFilters("mode", v)}
      />
      <SelectMap
        selected={map}
        onChange={(v) => updateFilters("map", v)}
        mode={mode}
      />
      {isCompetitive ? (
        <SelectRank
          selected={rank}
          onChange={(v) => updateFilters("rank", v)}
        />
      ) : null}
      <SelectAct selected={act} onChange={(v) => updateFilters("act", v)} />
    </FilterBar>
  );
};

export default function Agents() {
  const [searchText, setSearch] = useState("");
  const { searchParams } = useRoute();
  const { type } = getStatsParams(searchParams, "agents") as AgentStatsParams;
  const Component = useMemo(
    () => (type === "general" ? GeneralTableView : AbilitiesTableView),
    [type],
  );

  return (
    <ContentContainer className="flex column gap-sp-4">
      <Filters searchText={searchText} setSearch={setSearch} />
      <Component searchText={searchText} />
    </ContentContainer>
  );
}
